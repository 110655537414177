import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
// import { Redirect } from 'react-router';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Projects = lazy(() => import('./pages/Projects'));
const Resume = lazy(() => import('./pages/Resume'));
const ViewCV = lazy(() => import('./pages/ViewCV'));
const Stats = lazy(() => import('./pages/Stats'));
const Prop = lazy(() => import('./pages/Proposal'));
export class Redirect extends Component {
  constructor(props) {
    super();
    this.state = { ...props };
  }

  UNSAFE_componentWillMount() {
    window.location = this.state.route.loc;
  }

  render() {
    return (<section>Redirecting...</section>);
  }
}
const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Suspense fallback={<Main />}>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/cv-pdf" element={<ViewCV />} />
        <Route path="/downloadCV" element={<ViewCV />} />
        <Route path="/proposal" element={<Prop />} />
        <Route path="/shuyi" element={<Prop />} />
        <Route path="/myConfession" element={<Prop />} />
        <Route
          path="/privacy-policy"
          component={Redirect}
          loc="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
