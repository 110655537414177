import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Niran N</h2>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Niran. I am a MEXT Scholar & Masters student at <a href="https://www.u-tokyo.ac.jp/en/">The University of Tokyo.</a> Previously, I have worked as an AI/CV Researcher at Samsung Research, and at Informataica.<br /> As an <a href="https://developers.google.com/community/gdsc/leads">Ex-GDSC Lead, </a>
        Google Certified Tensorflow Developer and the best outgoing student -
        graduate from <a href="https://reva.edu.in/">REVA University, I push myself to explore beyond known boundaries. </a>
        I have worked on 3 Research papers, 10+ projects,
        1 international patent and managed 4 technical clubs.
      </p>
      <p>I love working in the field of AI, ML and IoT and am always in the
        loop to learn, research and contribute more.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">Made with 🍜 by Niran - &copy; <Link to="/">niran.dev</Link>.</p>
    </section>
  </section>
);

export default SideBar;
